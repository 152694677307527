html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
html,
body * {
  font-family: Avenir, sans-serif;
  -ms-content-zooming: none;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: Avenir;
  src: url("../public/font/1475532/17b90ef5-b63f-457b-a981-503bb7afe3c0.woff2") format("woff2"), url("../public/font/1475532/c9aeeabd-dd65-491d-b4be-3e0db9ae47a0.woff") format("woff");
}
@font-face {
  font-family: Heiti SC;
  src: url("../public/font/HeitSC/Heiti-SC.ttf") format("ttf");
}
