html,
body * {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide {
  display: none;
}
.odometer-inside > :last-child {
  opacity: 0;
  width: 0;
}
.slot-container {
  background-image: url("../../../../public/assets/main/main-bg.png");
  background-size: contain;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  min-height: 100vh;
  text-align: center;
}
.slot-container .title-img {
  width: 100%;
}
.slot-container .top-segment-row-unfixed .top-segment-col-unfixed .ant-segmented {
  background-color: #FFF;
}
.slot-container .top-segment-row-unfixed .top-segment-col-unfixed .ant-segmented .ant-segmented-group .ant-segmented-thumb {
  background: #FFF;
}
.slot-container .top-segment-row-unfixed .top-segment-col-unfixed .ant-segmented .ant-segmented-group .ant-segmented-item .ant-segmented-item-label {
  color: #6D6D6D;
  color: var(--grey-80, #6D6D6D);
}
.slot-container .top-segment-row-unfixed .top-segment-col-unfixed .ant-segmented .ant-segmented-group .ant-segmented-item.ant-segmented-item-selected {
  background: #0F0F0D;
}
.slot-container .top-segment-row-unfixed .top-segment-col-unfixed .ant-segmented .ant-segmented-group .ant-segmented-item.ant-segmented-item-selected .ant-segmented-item-label {
  color: #FFF;
}
.slot-container .top-segment-row .top-segment-col .ant-segmented {
  background-color: rgba(0, 0, 0, 0.2);
}
.slot-container .top-segment-row .top-segment-col .ant-segmented .ant-segmented-group .ant-segmented-thumb {
  background: #FFF;
}
.slot-container .top-segment-row .top-segment-col .ant-segmented .ant-segmented-group .ant-segmented-item .ant-segmented-item-label {
  color: #FFF;
}
.slot-container .top-segment-row .top-segment-col .ant-segmented .ant-segmented-group .ant-segmented-item.ant-segmented-item-selected {
  background: #0F0F0D;
}
.slot-container .top-segment-row .top-segment-col .ant-segmented .ant-segmented-group .ant-segmented-item.ant-segmented-item-selected .ant-segmented-item-label {
  color: #FFF;
}
.slot-container .top-segment-row,
.slot-container .top-segment-row-unfixed {
  padding: 20px 0;
}
.slot-container .top-segment-row .top-segment-col,
.slot-container .top-segment-row-unfixed .top-segment-col,
.slot-container .top-segment-row .top-segment-col-unfixed,
.slot-container .top-segment-row-unfixed .top-segment-col-unfixed {
  width: 100%;
  text-align: center;
}
.slot-container .top-segment-row .top-segment-col .ant-segmented,
.slot-container .top-segment-row-unfixed .top-segment-col .ant-segmented,
.slot-container .top-segment-row .top-segment-col-unfixed .ant-segmented,
.slot-container .top-segment-row-unfixed .top-segment-col-unfixed .ant-segmented {
  height: 42px;
  border-radius: 100px;
}
.slot-container .top-segment-row .top-segment-col .ant-segmented .ant-segmented-group .ant-segmented-thumb,
.slot-container .top-segment-row-unfixed .top-segment-col .ant-segmented .ant-segmented-group .ant-segmented-thumb,
.slot-container .top-segment-row .top-segment-col-unfixed .ant-segmented .ant-segmented-group .ant-segmented-thumb,
.slot-container .top-segment-row-unfixed .top-segment-col-unfixed .ant-segmented .ant-segmented-group .ant-segmented-thumb {
  height: 38px;
  border-radius: 100px;
  background: #0F0F0D;
}
.slot-container .top-segment-row .top-segment-col .ant-segmented .ant-segmented-group .ant-segmented-item,
.slot-container .top-segment-row-unfixed .top-segment-col .ant-segmented .ant-segmented-group .ant-segmented-item,
.slot-container .top-segment-row .top-segment-col-unfixed .ant-segmented .ant-segmented-group .ant-segmented-item,
.slot-container .top-segment-row-unfixed .top-segment-col-unfixed .ant-segmented .ant-segmented-group .ant-segmented-item {
  min-width: 68px;
}
.slot-container .top-segment-row .top-segment-col .ant-segmented .ant-segmented-group .ant-segmented-item.ant-segmented-item-selected,
.slot-container .top-segment-row-unfixed .top-segment-col .ant-segmented .ant-segmented-group .ant-segmented-item.ant-segmented-item-selected,
.slot-container .top-segment-row .top-segment-col-unfixed .ant-segmented .ant-segmented-group .ant-segmented-item.ant-segmented-item-selected,
.slot-container .top-segment-row-unfixed .top-segment-col-unfixed .ant-segmented .ant-segmented-group .ant-segmented-item.ant-segmented-item-selected {
  border-radius: 100px;
  background: #0F0F0D;
  min-width: 68px;
  height: 38px;
  flex-shrink: 0;
  text-align: center;
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.08px;
}
.slot-container .footer {
  padding: 20px 20px 35px 20px;
  background-image: url("../../../../public/assets/material/CoinGroup.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.slot-container .footer .text {
  color: #FFF;
  color: var(--white-100, #FFF);
  text-align: center;
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 22px;
  opacity: 0.80000001;
}
