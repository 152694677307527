html,
body * {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide {
  display: none;
}
.odometer-inside > :last-child {
  opacity: 0;
  width: 0;
}
.web-slot-container {
  background-image: url("../../../../public/assets/web/slot/Background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow-y: scroll;
  height: 100vh;
}
.web-slot-container .top-segment-row {
  padding: 50px 0;
  justify-content: space-between;
  padding-right: calc(12.5% + 20px);
}
.web-slot-container .top-segment-row .arrow-btn {
  padding-left: 30px;
}
.web-slot-container .top-segment-row .arrow-btn span {
  line-height: 48px;
}
.web-slot-container .top-segment-row .arrow-btn span img {
  height: 48px;
}
.web-slot-container .top-segment-row .split .split-line {
  height: 58px;
  border-right: 1px solid #fff;
  width: 55px;
  display: block;
}
.web-slot-container .top-segment-row .top-segment-col {
  padding-left: 20px;
}
.web-slot-container .top-segment-row .top-segment-col .ant-segmented {
  padding: 0;
  background-color: #FFF;
}
.web-slot-container .top-segment-row .top-segment-col .ant-segmented .ant-segmented-group {
  height: 58px;
}
.web-slot-container .top-segment-row .top-segment-col .ant-segmented .ant-segmented-group .ant-segmented-thumb {
  height: 58px;
  background-color: rgba(255, 255, 255, 0.9);
}
.web-slot-container .top-segment-row .top-segment-col .ant-segmented .ant-segmented-group .ant-segmented-item {
  border: 1px solid #D61C38;
  width: 339px;
  transition: background-color 0.2s ease-in-out;
}
.web-slot-container .top-segment-row .top-segment-col .ant-segmented .ant-segmented-group .ant-segmented-item .ant-segmented-item-label {
  color: #D61C38;
  line-height: 58px;
  font-size: 18px;
}
.web-slot-container .top-segment-row .top-segment-col .ant-segmented .ant-segmented-group .ant-segmented-item.ant-segmented-item-selected {
  background: #D61C38;
  min-width: 58px;
  flex-shrink: 0;
  text-align: center;
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.08px;
}
.web-slot-container .top-segment-row .top-segment-col .ant-segmented .ant-segmented-group .ant-segmented-item.ant-segmented-item-selected .ant-segmented-item-label {
  color: #FFF;
}
.web-slot-container .top-segment-row .top-history .history-btn {
  height: 58px;
  width: 100%;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.web-slot-container .top-segment-row .top-history .icon-history {
  background: url("../../../../public/assets/web/icon/icon-history.png") no-repeat;
  background-size: contain;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}
.web-slot-container .jackpot-wrapper {
  padding: 0 12.5% 150px 12.5%;
}
.web-slot-container .footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 68px;
  background: rgba(0, 0, 0, 0.8);
}
.web-slot-container .footer .text {
  text-align: center;
  width: 100%;
  line-height: 68px;
  color: #FFF;
}
