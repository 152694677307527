.web-his-item-container {
  padding: 10px 20px;
}
.web-his-item-container .content {
  background-image: url("../../../public/assets/web/material/gold-frame.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.web-his-item-container .content .no {
  color: #6D6D6D;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.web-his-item-container .content .logo-wrapper {
  padding: 20px 0;
}
.web-his-item-container .content .logo-wrapper img {
  width: 100%;
}
.web-his-item-container .content .info-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: right;
}
.web-his-item-container .content .info-wrapper .amount {
  font-size: 26px;
}
.web-his-item-container .content .info-wrapper .date {
  font-size: 20px;
  color: #6D6D6D;
}
