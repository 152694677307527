html,
body * {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide {
  display: none;
}
.odometer-inside > :last-child {
  opacity: 0;
  width: 0;
}
.web-dashboard-container {
  background-image: url("../../../../public/assets/dashboard/border.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 2px;
}
.web-dashboard-container .dashboard-content {
  background-image: url("../../../../public/assets/dashboard/bg.png");
  background-size: 100%;
  background-repeat: repeat;
  background-position: center;
}
.web-dashboard-container .dashboard-content .slot-content {
  flex-direction: column;
  padding: 20px;
}
.web-dashboard-container .dashboard-content .slot-content .card-bg {
  background-image: url("../../../../public/assets/dashboard/card.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.web-dashboard-container .dashboard-content .slot-content .card-bg .logo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.web-dashboard-container .dashboard-content .slot-content .card-bg .logo {
  padding: 8px 10px;
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.web-dashboard-container .dashboard-content .slot-content .card-bg .amount {
  color: #FFF;
  text-align: center;
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.2px;
}
.web-dashboard-container .dashboard-content .slot-content .card-bg .amount .data-wrapper {
  padding: 8px;
  text-align: left;
}
.web-dashboard-container .dashboard-content .slot-content .card-bg .amount .data-wrapper .type {
  font-size: 12px;
  background: linear-gradient(225deg, #FFE456 0%, #DCA141 28.6%, #FBEA54 46.57%, #F9B84F 91.1%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 8px;
}
.web-dashboard-container .dashboard-content .slot-content .card-bg .amount .data-wrapper .value {
  color: #FFF;
  font-family: Avenir;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.2px;
}
.web-dashboard-container .dashboard-content .slot-content .btn-wrapper {
  padding: 20px;
  text-align: center;
}
.web-dashboard-container .dashboard-content .slot-content .btn-wrapper .btn {
  background-image: url("../../../../public/assets/dashboard/Button.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 100px;
  color: #FFF;
  text-align: center;
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.14px;
  width: 270px;
  height: 48px;
}
.web-dashboard-container .dashboard-title-img {
  width: 100%;
}
