html,
body * {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide {
  display: none;
}
.odometer-inside > :last-child {
  opacity: 0;
  width: 0;
}
.his-container {
  background-image: url("../../../../public/assets/main/main-bg.png");
  background-size: contain;
  padding-top: 30px;
  min-height: 100vh;
}
.his-container .title-wrapper {
  text-align: center;
  padding: 8px 0;
}
.his-container .title-wrapper .title {
  color: #FFF;
  text-align: center;
  font-family: Heiti SC;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  /* 141.667% */
  letter-spacing: 0.12px;
  text-transform: uppercase;
}
.his-container .home-title-img {
  width: 100%;
}
.his-container .history-bg .history-content {
  background-image: url("../../../../public/assets/material/history-border.png");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 350px;
  height: 590px;
  margin: 0 auto;
  padding: 10px 0;
}
.his-container .history-bg .history-content .data-view {
  height: 575px;
  overflow: auto;
}
.his-container .history-bg .history-content .data-view .data-wrapper {
  padding: 0 20px 20px 20px;
}
.his-container .history-bg .history-content .data-view .data-wrapper .tb-body {
  border-bottom: 1px solid #EAEAEA;
  height: 85px;
  padding-top: 10px;
}
.his-container .history-bg .history-content .data-view .data-wrapper .tb-body .row-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.his-container .history-bg .history-content .data-view .data-wrapper .tb-body .no {
  align-items: center;
  color: #6D6D6D;
  color: var(--grey-80, #6D6D6D);
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: 18px;
  letter-spacing: 0.08px;
}
.his-container .history-bg .history-content .data-view .data-wrapper .tb-body .logo {
  align-items: center;
  padding: 5px;
}
.his-container .history-bg .history-content .data-view .data-wrapper .tb-body .logo img {
  width: 100%;
}
.his-container .history-bg .history-content .data-view .data-wrapper .tb-body .info {
  align-items: normal;
}
.his-container .history-bg .history-content .data-view .data-wrapper .tb-body .info .amount {
  color: #000;
  text-align: right;
  font-family: Avenir;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.2px;
}
.his-container .history-bg .history-content .data-view .data-wrapper .tb-body .info .date {
  color: #6D6D6D;
  color: var(--grey-80, #6D6D6D);
  text-align: right;
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 128.571% */
  letter-spacing: 0.07px;
}
.his-container .footer {
  padding: 8px 0;
  color: #FFF;
  color: var(--white-100, #FFF);
  text-align: center;
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 21px;
  /* 150% */
  letter-spacing: 0.07px;
}
