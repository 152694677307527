.jackpot-container.hidden {
  display: none;
}
.jackpot-container {
  padding: 10px 20px;
}
.jackpot-container .logo-wrapper img {
  width: 100%;
}
.jackpot-container .property-wrapper {
  padding: 16px 0 36px 0;
}
.jackpot-container .property-wrapper .property {
  background-image: url("../../../public/assets/material/Tag.png");
  background-repeat: no-repeat;
  background-position: center;
  color: #FFF;
  color: var(--white-100, #FFF);
  font-family: Avenir;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.06px;
  height: 32px;
  text-align: center;
}
.jackpot-container .property-wrapper .arrow-btn {
  text-align: right;
}
.jackpot-container .slot {
  margin-bottom: 20px;
  position: relative;
}
.jackpot-container .slot .link-bar-wrapper {
  position: absolute;
  bottom: -34px;
  height: 46px;
  width: 100%;
  z-index: 9;
}
.jackpot-container .slot .link-bar-wrapper .link-bar {
  background-image: url("../../../public/assets/material/iron-chain.png");
  background-repeat: no-repeat;
  height: 46px;
}
.jackpot-container .slot-block {
  background-image: url("../../../public/assets/material/card2.png");
}
.jackpot-container .slot-block .bit-wrapper {
  height: 0;
}
.jackpot-container .slot-block .bit-wrapper .amount-bit {
  top: -35px;
}
.jackpot-container .single-slot-block {
  background-image: url("../../../public/assets/material/card1.png");
}
.jackpot-container .single-slot-block .bit-wrapper {
  height: 0;
}
.jackpot-container .single-slot-block .bit-wrapper .amount-bit {
  top: -35px;
}
.jackpot-container .slot-block,
.jackpot-container .single-slot-block {
  padding: 20px 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.jackpot-container .slot-block .bit-wrapper,
.jackpot-container .single-slot-block .bit-wrapper {
  height: 0;
}
.jackpot-container .slot-block .bit-wrapper .amount-bit,
.jackpot-container .single-slot-block .bit-wrapper .amount-bit {
  color: #613400;
  text-align: center;
  font-family: Avenir;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.1px;
  position: relative;
}
.jackpot-container .slot-block .bit-wrapper .amount-bit .bit,
.jackpot-container .single-slot-block .bit-wrapper .amount-bit .bit {
  border-radius: 183px;
  background: linear-gradient(225deg, #FFE456 0%, #DCA141 15.97%, #FBDC54 46.57%, #DCA141 100%);
  display: inline-flex;
  height: 30px;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
}
.jackpot-container .slot-block .live-row,
.jackpot-container .single-slot-block .live-row {
  width: 100%;
  padding: 0 5px;
}
.jackpot-container .slot-block .live-row .type-wrapper,
.jackpot-container .single-slot-block .live-row .type-wrapper {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(225deg, #FFE456 0%, #DCA141 28.6%, #FBEA54 46.57%, #F9B84F 91.1%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.jackpot-container .slot-block .live-row .amount,
.jackpot-container .single-slot-block .live-row .amount {
  color: #FFF;
  text-align: center;
  font-family: Avenir;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.2px;
}
.jackpot-container .slot-block .live-row .amount .data-wrapper,
.jackpot-container .single-slot-block .live-row .amount .data-wrapper {
  padding: 8px 10px;
  text-align: left;
}
.jackpot-container .slot-block .live-row .amount .data-wrapper .type,
.jackpot-container .single-slot-block .live-row .amount .data-wrapper .type {
  background: linear-gradient(225deg, #FFE456 0%, #DCA141 28.6%, #FBEA54 46.57%, #F9B84F 91.1%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 8px;
}
.jackpot-container .slot-block .live-row .amount .data-wrapper .value,
.jackpot-container .single-slot-block .live-row .amount .data-wrapper .value {
  color: #FFF;
  font-family: Avenir;
  font-size: 28px;
  font-style: normal;
  font-weight: 900;
  text-align: right;
  line-height: normal;
  letter-spacing: 0.2px;
}
