html,
body * {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide {
  display: none;
}
.odometer-inside > :last-child {
  opacity: 0;
  width: 0;
}
.web-detail-container {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 100vh;
  overflow-y: scroll;
}
.web-detail-container .btn-area {
  padding-top: 30px;
}
.web-detail-container .btn-area .icon-back-btn {
  width: 50px;
  height: 50px;
  display: flex;
  background: url("../../../../public/assets/web/material/Black-Arrow.png") no-repeat;
  background-size: contain;
}
.web-detail-container .data-view {
  padding: 80px 40px 0 40px;
}
.web-detail-container .data-view .data-wrapper {
  padding: 0 20px;
}
.web-detail-container .data-view .data-wrapper .left-block {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.web-detail-container .data-view .data-wrapper .left-block .title {
  padding: 28px 0 16px 0;
  text-align: center;
  color: #141414;
  font-family: Avenir;
  white-space: pre-line;
  font-size: 54px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.09px;
  text-transform: uppercase;
}
.web-detail-container .data-view .data-wrapper .left-block .logo {
  text-align: center;
}
.web-detail-container .data-view .data-wrapper .left-block .logo img {
  width: 80%;
}
.web-detail-container .data-view .data-wrapper .data-area .tb-header {
  font-family: Avenir;
  font-size: 24px;
  font-weight: 900;
  line-height: 33px;
  letter-spacing: 0.005em;
  padding-bottom: 8px;
  color: #A1A1A1;
}
.web-detail-container .data-view .data-wrapper .data-area .tb-body {
  border-bottom: 1px solid #EAEAEA;
  height: 50px;
  padding-top: 10px;
}
.web-detail-container .data-view .data-wrapper .data-area .tb-body .no,
.web-detail-container .data-view .data-wrapper .data-area .tb-body .date {
  font-family: Avenir;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.005em;
  color: #6D6D6D;
}
.web-detail-container .data-view .data-wrapper .data-area .tb-body .amount {
  font-family: Avenir;
  font-size: 20px;
  font-weight: 900;
  line-height: 27px;
  letter-spacing: 0.01em;
  text-align: right;
  color: #141414;
}
