html,
body * {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide {
  display: none;
}
.odometer-inside > :last-child {
  opacity: 0;
  width: 0;
}
.detail-container {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100vh;
  overflow-y: scroll;
}
.detail-container .data-view {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
}
.detail-container .data-view .data-wrapper {
  background-size: 100% 90%;
  background-repeat: no-repeat;
  background-position: center bottom;
  width: 100%;
  height: 100%;
  padding: 80px 65px;
}
.detail-container .data-view .data-wrapper .data-parent {
  position: relative;
  height: 100%;
  width: 100%;
}
.detail-container .data-view .data-wrapper .data-parent .data-area {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80%;
}
.detail-container .data-view .data-wrapper .data-parent .data-area .title {
  padding-bottom: 16px;
  color: #141414;
  color: var(--grey-100, #141414);
  text-align: center;
  font-family: Avenir;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.09px;
  text-transform: uppercase;
}
.detail-container .data-view .data-wrapper .data-parent .data-area .tb-header {
  color: #6D6D6D;
  color: var(--grey-80, #6D6D6D);
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.07px;
  padding-bottom: 8px;
}
.detail-container .data-view .data-wrapper .data-parent .data-area .tb-body {
  border-bottom: 1px solid #EAEAEA;
  height: 35px;
}
.detail-container .data-view .data-wrapper .data-parent .data-area .tb-body .no,
.detail-container .data-view .data-wrapper .data-parent .data-area .tb-body .date {
  color: #6D6D6D;
  color: var(--grey-80, #6D6D6D);
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: 16px;
  letter-spacing: 0.08px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.detail-container .data-view .data-wrapper .data-parent .data-area .tb-body .no {
  align-items: center;
}
.detail-container .data-view .data-wrapper .data-parent .data-area .tb-body .date {
  align-items: flex-start;
}
.detail-container .data-view .data-wrapper .data-parent .data-area .tb-body .amount {
  color: #141414;
  color: var(--grey-100, #141414);
  font-family: Avenir;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
