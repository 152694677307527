html,
body * {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hide {
  display: none;
}
.odometer-inside > :last-child {
  opacity: 0;
  width: 0;
}
.web-his-container {
  background-image: url("../../../../public/assets/web/material/history-bg.png");
  background-size: 100% 100%;
  padding-top: 30px;
  min-height: 100vh;
}
.web-his-container .header-row {
  padding: 50px 40px 20px 40px;
}
.web-his-container .header-row .arrow-btn img {
  height: 40px;
}
.web-his-container .header-row .top-title-col {
  text-align: center;
  font-size: 40px;
  color: #FFF;
}
.web-his-container .history-bg .history-content {
  padding: 0 120px;
}
